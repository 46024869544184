import { defineStore } from 'pinia'
import { useAxios } from '@/Helpers/useAxios.js'
import cloneDeep from 'lodash.clonedeep'
import { useAirportStore } from '@/Stores/Airport.js'
import { useFlightDatesStore } from '@/Stores/FlightDates.js'

export const useOffersStore = defineStore('offers', () => {
    const router = useRouter()
    const route = useRoute()

    const loading = reactive({
        items: true,
        filter: true,
        loadMore: false
    })

    const total = ref(0)
    const totalPages = ref(0)

    const items = ref([])
    const filters = ref([])

    const params = reactive({
        q: '',
        startDate: '',
        endDate: '',
        adults: 2,
        children: [],
        region: '',
        regionGroup: '',
        locationName: '',
        location: '',
        productType: '',
        departureAirport: '',
        page: 1,
        filter_category: [],
        filter_location: [],
        filter_rating: [],
        filter_serviceType: [],
        filter_roomType: [],
        filter_name: '',
        debug: true,
        sort: 'price;asc'
    })

    const disableSearch = computed(() => {
        if (params.startDate == '' || params.endDate == '') {
            return true
        }
        return false
    })

    const enableResetFilter = computed(() => {
        return params.filter_category.length || params.filter_location.length || params.filter_rating.length || params.filter_serviceType.length || params.filter_name
    })

    const resetFilter = () => {
        params.filter_roomType = params.filter_category = params.filter_location = params.filter_rating = params.filter_serviceType = []
        params.filter_name = ''
    }

    watch(
        () => [params.filter_category, params.filter_location, params.filter_rating, params.filter_serviceType, params.filter_roomType, params.sort],
        () => {
            params.page = 1
        },
        { deep: true }
    )

    watch(
        () => [params.filter_category, params.filter_location, params.filter_rating, params.filter_serviceType, params.sort, params.filter_roomType],
        () => {
            filterData()
        },
        { deep: true }
    )

    watch(
        () => [params.regionGroup, params.region, params.location],
        () => {
            useAirportStore().loadData(params)
        },
        { deep: true }
    )

    watch(
        () => [params.departureAirport],
        () => {
            useFlightDatesStore().loadData(params)
        },
        { deep: true }
    )

    const filterData = () => {
        if (loading.items == true) {
            return
        }

        const options = {}

        options.onBefore = () => {
            loading.items = true
        }

        options.onSuccess = (response) => {
            items.value = Object.values(response.items)
            total.value = response.totalResultCount
            totalPages.value = response.totalPages
        }

        options.onFinish = () => {
            loading.items = false
            router.push({ name: 'offers', query: cloneDeep(params) })
        }

        fetchData(options)
    }

    const search = () => {
        router.push({ name: 'offers', query: cloneDeep(params) }).then(() => {
            router.go()
        })
    }

    const update = () => {
        if (!params.startDate || !params.endDate) {
            return
        }

        const options = {}

        options.onBefore = () => {
            loading.enableResults = loading.items = loading.filter = true
        }

        options.onSuccess = (response) => {
            items.value = Object.values(response.items)
            total.value = response.totalResultCount
            totalPages.value = response.totalPages
            filters.value = response.filters
        }

        options.onFinish = () => {
            loading.filter = loading.items = false
        }

        fetchData(options)
    }

    const loadMore = () => {
        const options = {}

        params.page++

        options.onBefore = () => {
            loading.loadMore = true
        }

        options.onSuccess = (response) => {
            items.value.push(...Object.values(response.items))
        }

        options.onFinish = () => {
            loading.loadMore = false
        }

        fetchData(options)
    }

    const fetchData = (options) => {
        useAxios.get('/api/offers', params, {
            onBefore: () => {
                options.onBefore()
            },
            onSuccess: (response) => {
                options.onSuccess(response)
            },
            onFinish: () => {
                options.onFinish()
            }
        })
    }

    onMounted(() => {
        router.isReady().then(async () => {
            _.assign(params, route.query)
            update()
        })
    })

    return {
        params,
        items,
        filters,
        disableSearch,
        enableResetFilter,
        loading,
        total,
        totalPages,
        filterData,
        resetFilter,
        search,
        loadMore
    }
})
